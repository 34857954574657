<template>
  <div class="page-div">
    <div class="page-menu-div" :class="{'page-menu-div-open':isShowMenuDiv, 'page-menu-div-hidden':!isShowMenuDiv}">
      <div class="logo-div">
        <img :src="`${$imgBaseURL}/${this.$store.state.user.firm.logo}`" alt="">
      </div>
      <div class="firm-name-div text-c">
        <div>{{ this.$store.state.user.firm.name }}</div>
        <div>管理会后台</div>
      </div>
      <div :class="{'menu-item-div':true, 'menu-activity-div':currentMenuIndex === 1}" @click="onShowRightPage(1, 'Main')">
        <label class="menu-icon-label"><img src="../assets/menu-sy.png" alt=""/></label>
        <label>首页</label>
      </div>
      <div :class="{'menu-item-div':true, 'menu-activity-div':currentMenuIndex === 2}" @click="onShowRightPage(2, 'Members')">
        <label class="menu-icon-label"><img src="../assets/menu-cylb.png" alt=""/></label>
        <label>成员列表</label>
      </div>
      <div :class="{'menu-item-div':true, 'menu-activity-div':currentMenuIndex === 3}" @click="onShowRightPage(3, 'ApplyJoins')">
        <label class="menu-icon-label"><img src="../assets/menu-rhsq.png" alt=""/></label>
        <label>入会申请</label>
      </div>
      <div :class="{'menu-item-div':true, 'menu-activity-div':currentMenuIndex === 4}" @click="onShowRightPage(4, 'ApplyAdds')">
        <label class="menu-icon-label"><img src="../assets/menu-jrsq.png" alt=""/></label>
        <label>加入申请</label>
      </div>
      <div :class="{'menu-item-div':true, 'menu-activity-div':currentMenuIndex === 5}" @click="onShowRightPage(5, 'Comments')">
        <label class="menu-icon-label"><img src="../assets/menu-plgl.png" alt=""/></label>
        <label>评论管理</label>
      </div>
    </div>
    <div class="page-content-div">
      <div class="top-div">
        <div>
          <label class="menu-img-label" @click="onShowMenu"><img src="../assets/menu.png" height="33" width="41" alt=""/></label>
        </div>
        <div>
          <ul class="menu-nav">
            <li class="menu-li">
              <label class="menu-name-label cursor-el" @click="onEditFirm">商会编辑</label>
            </li>
            <li class="menu-li">
              <label class="menu-name-label cursor-el">{{ this.$store.state.user.name }}</label>
              <ul class="nav">
                <li><label class="cursor-el" @click="onShowEditSelfPwd">修改密码</label></li>
              </ul>
            </li>
            <li class="menu-li">
              <label class="menu-name-label cursor-el" @click="onLogout">安全退出</label>
            </li>
          </ul>
        </div>
      </div>
      <div class="child-page-content-div">
        <router-view class="child-page-view" @handleToggle="togglePage" />
      </div>
    </div>
  </div>
</template>

<script>
import PersonalPwdEditLayer from "../pages/layer/PersonalPwdEditLayer";
import FirmEditLayer from "../pages/layer/FirmEditLayer";
import { getFirmSimpleStatistics, postLogout } from "../common/api";

export default {
  name: "Home",
  data() {
    return {
      isShowMenuDiv: true,
      currentMenuIndex: 1
    };
  },
  created() {
    let routeName = this.$router.currentRoute.name;
    switch (routeName) {
      case "Main":
        this.currentMenuIndex = 1;
        break;
      case "Members":
        this.currentMenuIndex = 2;
        break;
      case "ApplyJoins":
        this.currentMenuIndex = 3;
        break;
      case "ApplyAdds":
        this.currentMenuIndex = 4;
        break;
      case "Comments":
        this.currentMenuIndex = 5;
        break;
      default: break;
    }
    this.getToBeReviewed();
  },
  methods: {
    getToBeReviewed() {
      if(this.$store.state.user.isLogin){
        getFirmSimpleStatistics().then(data => {
          this.$store.commit("setStatistics", data);
          this.reviewedTimer();
        }).catch(error => {
          this.reviewedTimer();
        });
      }
    },
    reviewedTimer() {
      if(this.$store.state.user.isLogin){
        this.reviewedTimeCounter = setTimeout(() => {
          clearTimeout(this.reviewedTimeCounter);
          this.getToBeReviewed();
        }, 15000);
      }else{
        this.$store.state.reviewTips = [];
      }
    },
    onShowMenu() {
      this.isShowMenuDiv = !this.isShowMenuDiv;
    },
    onShowRightPage(menuIndex, pageName) {
      this.currentMenuIndex = menuIndex;
      if(pageName !== this.$route.name){
        this.$router.push({ name: pageName });
      }
    },
    togglePage(menuIndex, pageName) {
      this.onShowRightPage(menuIndex, pageName)
    },
    onEditFirm() {
      this.$layer.iframe({
        content: {
          content: FirmEditLayer,
          parent: this
        },
        area: ["600px", "630px"],
        title: "编辑商会信息"
      });
    },
    onShowEditSelfPwd() {
      this.$layer.iframe({
        content: {
          content: PersonalPwdEditLayer,
          parent: this
        },
        area: ["580px", "380px"],
        title: "修改密码信息"
      });
    },
    onLogout() {
      postLogout()
        .then(() => {
          this.$store.commit("setUser", { isLogin: false });
          this.$router.push({ name: "Login" });
        })
        .catch(() => {
          this.$store.commit("setUser", { isLogin: false });
          this.$router.push({ name: "Login" });
        });
    }
  }
};
</script>

<style lang="less" scoped>
.page-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  .page-menu-div {
    height: 100%;
    color: white;
    background-color: #002345;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .logo-div {
      width: 100%;
      margin-top: 15px;
      text-align: center;
      margin-bottom: 15px;
      img {
        width: 64px;
        height: 64px;
        border-radius: 32px;
      }
    }
    .firm-name-div {
      width: 100%;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 30px;
      div {
        height: 28px;
        overflow: hidden;
      }
    }
    .menu-item-div {
      width: 258px;
      height: 50px;
      font-size: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .menu-icon-label {
        margin-left: 15px;
        margin-right: 10px;
        img {
          width: 25px;
          height: 25px;
          vertical-align: middle;
        }
      }
    }
    .menu-item-div:last-child {
      margin-top: 0;
    }
    .menu-activity-div {
      background-color: #103860;
      border-radius: 25px 0 0 25px;
    }
  }
  .page-menu-div-open {
    width: 288px;
    transition: width 0.5s;
  }
  .page-menu-div-hidden {
    width: 0;
    transition: width 0.5s;
  }
  .page-content-div {
    flex: 1;
    overflow: auto;
    .top-div {
      height: 44px;
      padding-top: 10px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        list-style: none;
      }
      .menu-img-label {
        margin-left: 15px;
        img {
          vertical-align: middle;
        }
      }
      .menu-nav {
        height: 100%;
        color: #002345;
        .menu-li {
          float: left;
          position: relative;
          label {
            display: block;
            height: 44px;
            min-width: 100px;
            text-align: center;
            line-height: 44px;
          }
        }
        li:hover .nav {
          display: block;
        }
        li:hover .menu-name-label {
          background-color: rgba(255, 255, 255, 0.5);
        }
        .nav {
          display: none;
          position: absolute;
          top: 44px;
          left: 0;
          background-color: white;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
          label {
            border-top: 1px solid #efefef;
          }
          label:hover {
            color: #0066cc;
          }
        }
      }
    }
    .child-page-content-div {
      width: 100%;
      .child-page-view {
        width: calc(100% - 60px);
        margin: 20px auto 15px auto;
      }
    }
  }
}
</style>
