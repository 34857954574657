<template>
  <div class="layer-page-div">
    <div class="item-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <label class="item-name-label">当前密码</label>
          <validation-provider rules="required|minmax:6,18" v-slot="{ errors }" name="password">
            <label>
              <input type="password" v-model="oldPwd" placeholder="请输入登录密码" />
            </label>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="item-div">
          <label class="item-name-label">新的密码</label>
          <validation-provider rules="required|minmax:6,18" v-slot="{ errors }" name="password">
            <label>
              <input type="password" v-model="pwd1" placeholder="请输入登录密码" />
            </label>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="item-div">
          <label class="item-name-label">确认密码</label>
          <validation-provider rules="required|confirmed:password" v-slot="{ errors }" name="confirmPwd">
            <label>
              <input type="password" v-model="pwd2" placeholder="请确认密码" />
            </label>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
      </validation-observer>
    </div>
    <div class="submit-div">
      <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
      <input type="button" value="确  定" @click="onSubmit" />
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
import { postEditOwnPwd } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      oldPwd: "",
      pwd1: "",
      pwd2: "",
      tipMsg: ""
    };
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then(res => {
        if (res) {
          if (this.oldPwd === this.pwd1) {
            this.setTipsMsg("新密码不能与当前使用密码相同");
            return;
          }

          postEditOwnPwd({ pwd: this.$sraEncode(md5(this.oldPwd)), newPwd: this.$sraEncode(this.pwd1) })
            .then(() => {
              this.$layer.close(this.layerid);
              this.$layer.msg("修改密码成功");

              this.$store.commit("setUser", { isLogin: false });
              this.$router.push({ name: "Login" });
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("修改密码失败");
              }
            });
        }
      });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
};
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .item-content-div {
    width: 320px;
    margin: 20px auto 0 auto;
    .item-div {
      .item-name-label {
        margin-right: 10px;
      }
      input {
        width: 220px;
        height: 33px;
        padding: 0 5px;
        border: 1px solid #dcdcdc;
        margin-right: 5px;
      }
      .tips-div {
        width: 232px;
        height: 30px;
        margin-left: 80px;
      }
    }
  }
  .submit-div {
    text-align: center;
    margin-top: 15px;
    input {
      width: 120px;
      height: 35px;
      color: white;
      font-size: 16px;
      border: none;
      background: #4676c8;
      margin-top: 10px;
    }
    .tips-div {
      width: 100%;
      height: 25px;
    }
  }
}
</style>
