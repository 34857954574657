import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import layer from "vue-layer"
import JSEncrypt from "jsencrypt"
import init from "./common/init";
import "vue-layer/lib/vue-layer.css"
import "./common/validate"

Vue.config.productionTip = false
Vue.prototype.$layer = layer(Vue)

router.beforeEach((to, from, next) => {
  if (!store.state.initResult) {
    if (to.path === "/500") {
      next();
    } else {
      next({ path: "/500" });
    }
    return;
  }

  if (to.path === "/login") {
    if (store.state.user.isLogin) {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    if (to.meta.requireAuth) {
      if (store.state.user.isLogin) {
        next();
      } else {
        next("/login");
      }
    } else {
      next();
    }
  }
})

router.afterEach(() => {
  window.scrollTo(0, 0);
})

init.initData(function(publicKey) {
  if (publicKey && publicKey.length > 0) {
    Vue.prototype.$sraEncode = function(str) {
      let encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);
      return encrypt.encrypt(str);
    };
  }

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
})
