<template>
  <div class="layer-page-div">
    <div class="big-img-div" v-show="isShowBigImg" @click="onHideBigImgDiv">
      <img ref="bigImg" src="" alt=""/>
    </div>
    <div class="layer-content-div">
      <div class="item-div">
        <div>商会名称：{{ firmInfo.name }}</div>
        <div class="item-logo-div">
          <label>商会LOGO：</label>
          <div class="firm-logo-div">
            <div class="logo-img-div" v-if="firmInfo.logo.data != null">
              <img class="del-img" src="../../assets/del.png" alt="" @click="onDelLogo" />
              <img v-if="firmInfo.logo.file == null" :src="`${$imgBaseURL}/${firmInfo.logo.data}`" alt="">
              <img v-else :src="firmInfo.logo.data" alt="">
            </div>
            <div v-else>
              <label class="upload-img-label" @change="onAddLogo($event)">
                <img class="cursor-el" src="../../assets/picture.png" alt="" />
                <input class="cursor-el" type="file" />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="middle-item-div">
        <div>
          <div>
            <label>商会电话：</label>
            <label><input type="text" v-model="firmInfo.phone" maxlength="11" placeholder="请输入电话"></label>
          </div>
          <div class="email-div">
            <label>商会邮箱：</label>
            <label><input type="email" v-model="firmInfo.email" maxlength="60" placeholder="请输入邮箱"></label>
          </div>
        </div>
        <div class="middle-right-div">
          <div>商会图片</div>
          <div class="firm-img-div">
            <div v-for="(v, k) in firmInfo.img" class="preview-img-div">
              <img class="del-img" src="../../assets/del.png" alt="" @click="onDelImg(k)">
              <img v-if="Object.prototype.hasOwnProperty.call(v, 'index')" class="preview-img" :src="`${$imgBaseURL}/${v.data}`" alt="" @click="onShowBigImgDiv($event)">
              <img v-else class="preview-img" :src="v.data" alt="" @click="onShowBigImgDiv($event)">
            </div>
            <div v-show="firmInfo.img.length < 3">
              <label class="upload-img-label" @change="onAddImg($event)">
                <img class="cursor-el" src="../../assets/picture.png" alt="" />
                <input class="cursor-el" type="file" />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="firm-prefecture-div">
        <label>商会地区：</label>
        <label>
          <select class="prefecture-select" v-model="firmInfo.provinceId" name="province" @change="onProvinceCity($event)">
            <option value="">请选择省份</option>
            <option v-for="item in firmInfo.provinces" :value="item.id">{{item.name}}</option>
          </select>
        </label>
        <label>
          <select class="prefecture-select" v-model="firmInfo.cityId" name="city" @change="onProvinceCounty($event)">
            <option value="">请选择城市</option>
            <option v-for="item in firmInfo.cities" :value="item.id">{{item.name}}</option>
          </select>
        </label>
        <label>
          <select class="prefecture-select" v-model="firmInfo.areaId" name="area">
            <option value="">请选择地区</option>
            <option v-for="item in firmInfo.counties" :value="item.id">{{item.name}}</option>
          </select>
        </label>
      </div>
      <div class="firm-address">
        <label>商会地址：</label>
        <label><input type="text" v-model="firmInfo.address" maxlength="50" placeholder="请输入商会地址"></label>
      </div>
      <div class="firm-content-title-div">商会简介</div>
      <div class="firm-content-div">
        <label>
          <textarea v-model="firmInfo.content" maxlength="2000" placeholder="请输入商会简介"></textarea>
        </label>
      </div>
      <div class="operate-div">
        <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
        <div class="text-c">
          <input type="button" value="取消" :disabled="isLoading" @click="onCancel">
          <input class="save-input" type="button" value="保存" :disabled="isLoading" @click="onSave">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFirmDetails, getProvinces, getCities, getCounties, postFirmEdit, getFirmInfo } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      firmInfo: {
        logo: {data: null, file: null},
        img: [],
        provinces: [],
        cities: [],
        counties: [],
      },
      delImgIndex: [],
      isShowAddLogo: true,
      isShowBigImg: false,
      tipMsg: "",
      isLoading: false
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getFirmDetails()
          .then(data => {
            if(Object.prototype.hasOwnProperty.call(data, "logo")){
              data.logo = {data: data.logo, file: null};
            }
            if(Object.prototype.hasOwnProperty.call(data, "img")){
              let img = [];
              data.img.forEach((v, index) => {
                img.push({
                  index: index,
                  data: v,
                });
              })
              data.img = img;
            }else{
              data.img = [];
            }
            data.provinces = [];
            data.cities = [];
            data.counties = [];
            this.firmInfo = data;
            this.onProvinceChanged();
            this.onProvinceCity(null, data.provinceId);
            this.onProvinceCounty(null, data.cityId);
          })
          .catch(error => {
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("查询信息失败，请稍后重试");
            }
            this.$layer.close(this.layerid);
          });
    },
    onProvinceChanged() {
      getProvinces()
          .then(data => {
            this.firmInfo.provinces = data;
          })
          .catch(error => {
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("查询省份信息失败，请稍后重试");
            }
          });
    },
    onProvinceCity(event, province) {
      let provinceId = event == null ? province : event.target.value;
      if(provinceId > 0){
        getCities({provinceId: provinceId})
            .then(data => {
              this.firmInfo.cities = data;
              if(province == null){
                this.firmInfo.cityId = "";
                this.firmInfo.counties = [];
                this.firmInfo.areaId = "";
              }
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("查询城市信息失败，请稍后重试");
              }
            });
      }else{
        this.firmInfo.cities = [];
        this.firmInfo.cityId = "";
        this.firmInfo.counties = [];
        this.firmInfo.areaId = "";
      }
    },
    onProvinceCounty(event, city) {
      let cityId = event == null ? city : event.target.value;
      if(cityId > 0){
        getCounties({cityId: cityId})
            .then(data => {
              this.firmInfo.counties = data;
              if(city == null){
                this.firmInfo.areaId = "";
              }
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("查询地区信息失败，请稍后重试");
              }
            });
      }else{
        this.firmInfo.counties = [];
        this.firmInfo.areaId = "";
      }
    },
    onDelLogo() {
      this.firmInfo.logo.data = null;
      this.firmInfo.logo.file = null;
    },
    onAddLogo(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg和png格式");
        return;
      }

      let img = event.target.files[0];
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        that.firmInfo.logo.data = reader.result;
        that.firmInfo.logo.file = img;
        that.isShowAddLogo = false;
        event.target.value = null;
      };
      reader.onerror = function() {
        this.onDelImg();
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onDelImg(index) {
      let item = this.firmInfo.img.splice(index, 1);
      if(Object.prototype.hasOwnProperty.call(item[0], "index")){
        this.delImgIndex.push(item[0].index);
      }
    },
    onAddImg(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg和png格式");
        return;
      }

      let img = event.target.files[0];
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        that.firmInfo.img.push({
          file: img,
          data: reader.result,
        });
        event.target.value = null;
      };
      reader.onerror = function() {
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onShowBigImgDiv(event) {
      this.$refs.bigImg.src = event.target.src;
      this.isShowBigImg = true;
    },
    onHideBigImgDiv() {
      this.isShowBigImg = false;
    },
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      if(this.isLoading){
        return;
      }

      let formData = new FormData();
      if(this.firmInfo.phone.length < 1){
        this.setTipsMsg("请填写商会电话");
        return;
      }
      if(!/^1\d{10}$/.test(this.firmInfo.phone)){
        this.setTipsMsg("手机号格式错误，请重新填写");
        return;
      }
      if(!/(^$)|(^[A-Za-z0-9u4e00-u9fa5]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$)/.test(this.firmInfo.email)){
        this.setTipsMsg("邮箱格式错误，请重新填写");
        return;
      }
      if(this.firmInfo.provinceId < 1 || this.firmInfo.cityId < 1 || this.firmInfo.areaId < 1){
        this.setTipsMsg("请选择商会所在地区");
        return;
      }
      if(this.firmInfo.address.length < 1){
        this.setTipsMsg("请填写商会地址");
        return;
      }
      if(this.firmInfo.content.length < 1){
        this.setTipsMsg("请填写商会简介");
        return;
      }

      formData.append("content", this.firmInfo.content);
      formData.append("phone", this.firmInfo.phone);
      formData.append("email", this.firmInfo.email);
      formData.append("provinceId", this.firmInfo.provinceId);
      formData.append("cityId", this.firmInfo.cityId);
      formData.append("areaId", this.firmInfo.areaId);
      formData.append("address", this.firmInfo.address);
      if(this.firmInfo.logo.file != null){
        formData.append("logoFile", this.firmInfo.logo.file);
      }
      this.firmInfo.img.forEach(v => {
        formData.append("file", v.file);
      })
      this.delImgIndex.forEach(v => {
        formData.append("delImg", v);
      })

      this.isLoading = true;
      postFirmEdit(formData)
        .then(() => {
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
          this.isLoading = false;
          this.getFirmMsg();
        })
        .catch(error => {
          this.isLoading = false;
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.setTipsMsg(error.data.msg);
          } else {
            this.setTipsMsg("操作失败");
          }
        });
    },
    getFirmMsg() {
      getFirmInfo().then(data => {
        let user = this.$store.state.user;
        user.firm = data
        this.$store.commit("setUser", user);
      }).catch(error => {});
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .big-img-div {
    position: absolute;
    width: 100%;
    height: calc(100% - 42px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      align-items: center;
    }
  }
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item-logo-div {
        display: flex;
        align-items: center;
        .logo-img-div {
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            border-radius: 5px;
            align-items: center;
          }
          .del-img {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }
    .firm-prefecture-div {
      margin-top: 15px;
      .prefecture-select {
        height: 33px;
        border: 1px solid #dcdcdc;
        margin-right: 5px;
        border-radius: 3px;
      }
    }
    .middle-item-div {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      .email-div {
        margin-top: 15px;
      }
      input {
        width: 158px;
        height: 33px;
        padding: 0 5px;
        border: 1px solid #dcdcdc;
        border-radius: 3px;
      }
      .middle-right-div {
        width: 260px;
        overflow: hidden;
        .firm-img-div {
          margin-top: 5px;
          display: flex;
          .preview-img-div {
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-right: 10px;
            .del-img {
              width: 16px;
              height: 16px;
              position: absolute;
              top: 0;
              right: 0;
            }
            .preview-img {
              width: auto;
              height: auto;
              max-width: 100%;
              max-height: 100%;
              align-items: center;
              border-radius: 5px;
            }
          }
        }
      }
    }
    .firm-address {
      margin-top: 15px;
      input {
        width: 478px;
        height: 33px;
        padding: 0 5px;
        border: 1px solid #dcdcdc;
        border-radius: 3px;
      }
    }
    .firm-content-title-div {
      margin-top: 15px;
    }
    .firm-content-div {
      margin-top: 15px;
      textarea {
        width: calc(100% - 10px);
        height: 130px;
        resize: none;
        padding: 5px;
        border: 1px solid #dcdcdc;
        border-radius: 5px;
        background-color: #f4f4f4;
      }
    }
    .operate-div {
      margin-top: 15px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        border-radius: 17px;
        background-color: #002345;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>