import store from "../store/index.js";
import { getPublicKey } from "./api";

export function initData(callback) {
  getPublicKey()
    .then(data => {
      let user = JSON.parse(sessionStorage.getItem("user"));
      if (user instanceof Object && typeof user.isLogin === "boolean") {
        store.commit("setUser", user);
      }
      store.commit("setInitResult", true);
      callback(data);
    })
    .catch(() => {
      callback();
    });
}

export default {
  initData
};
