<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

button:focus,
textarea:focus,
select:focus {
  outline: none;
}

input {
  outline: none;
}

button,
input[type="button"],
input[type="submit"],
input[type="reset"],
.cursor-el {
  cursor: pointer;
}

a {
  color: #2c3e50;
  text-decoration: none;
}

td .goods-img-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

td .goods-img-div img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #2c3e50;
}

.text-c {
  text-align: center;
}

.default-bg-color {
  background-color: #207ab7;
}

.success-txt {
  color: #5eb95e;
}

.red-txt {
  color: #ea3535;
}

.err-tips {
  color: red;
}

.upload-img-label {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.upload-label {
  width: 70px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.ellipsis-content-div {
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
