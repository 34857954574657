import https from "./https";

export const getCancelTokenSource = () => { return https.getCancelTokenSource() };

export const verifyCodeImg = (acc, random) => { return `${https.getBaseURL()}/user-server/nk/manager/v1/validate/code?acc=${acc}&r=${random}`};

export const getPublicKey = () => { return https.doGet("user-server/nk/manager/v1/key") };
export const getValidateAcc = params => { return https.doGet("user-server/nk/manager/v1/validate/acc", params) };
export const postLogin = params => { return https.doPost("user-server/nk/manager/v1/login", params) };
export const postLogout = () => { return https.doPost("user-server/yk/manager/v1/logout") };
export const postEditOwnPwd = params => { return https.doPost("user-server/yk/manager/v1/edit/pwd", params) };

export const getProvinces = () => { return https.doGet("manage-server/nk/prefecture/v1/province") };
export const getCities = params => { return https.doGet("manage-server/nk/prefecture/v1/city", params) };
export const getCounties = params => { return https.doGet("manage-server/nk/prefecture/v1/county", params) };

export const getFirmInfo = () => { return https.doGet("manage-server/yk/firm/v1/info") };
export const getFirmSimpleStatistics = () => { return https.doGet("manage-server/yk/firm/v1/simple/statistics") };
export const getFirmDetails = () => { return https.doGet("manage-server/yk/firm/v1/msg") };
export const postFirmEdit = params => { return https.doPost("manage-server/yk/firm/v1/msg/edit", params) };
export const getFirmMembers = () => { return https.doGet("manage-server/yk/firm/v1/member/items") };
export const getFirmMemberMsg = params => { return https.doGet("manage-server/yk/firm/v1/member/msg", params) };

export const getFirmMemberApplyJoins = () => { return https.doGet("manage-server/yk/firm/v1/apply/join/items") };
export const postFirmApplyJoinPass = params => { return https.doPost("manage-server/yk/firm/v1/apply/join/pass", params) };
export const postFirmApplyJoinDel = params => { return https.doPost("manage-server/yk/firm/v1/apply/join/del", params) };

export const getFirmMemberApplyAdds = () => { return https.doGet("manage-server/yk/firm/v1/apply/add/items") };
export const postFirmApplyAddPass = params => { return https.doPost("manage-server/yk/firm/v1/apply/add/pass", params) };
export const postFirmApplyAddDel = params => { return https.doPost("manage-server/yk/firm/v1/apply/add/del", params) };

export const getFirmCommentItems = params => { return https.doGet("manage-server/yk/firm/v1/comment/items", params) };
export const postFirmCommentDel = params => { return https.doPost("manage-server/yk/firm/v1/comment/del", params) };
export const postFirmCommentReply = params => { return https.doPost("manage-server/yk/firm/v1/comment/reply", params) };
