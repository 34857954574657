import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  } else {
    return originalPush.call(this, location).catch(err => err);
  }
}

VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject);
  } else {
    return originalReplace.call(this, location).catch(err => err);
  }
}

const routes = [
  {
    path: '/',
    component: Home,
    meta: { requireAuth: true },
    children: [
      { path: "/", name: "Main", meta: { requireAuth: true }, component: () => import("../pages/Main") },
      { path: "/members", name: "Members", meta: { requireAuth: true }, component: () => import("../pages/Members") },
      { path: "/apply/joins", name: "ApplyJoins", meta: { requireAuth: true }, component: () => import("../pages/ApplyJoins") },
      { path: "/apply/adds", name: "ApplyAdds", meta: { requireAuth: true }, component: () => import("../pages/ApplyAdds") },
      { path: "/comments", name: "Comments", meta: { requireAuth: true }, component: () => import("../pages/Comments") }
    ]
  },
  {
    path: "/login",
    name: "Login",
    meta: { requireAuth: false },
    component: () => import("../views/Login.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
