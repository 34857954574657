import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    initResult: false,
    user: { isLogin: false },
    statistics: {}
  },
  mutations: {
    setInitResult(state, result) {
      state.initResult = result;
    },
    setUser(state, user) {
      sessionStorage.setItem("user", JSON.stringify(user));
      state.user = Object.assign({}, user);
    },
    setStatistics(state, statistics) {
      state.statistics = statistics;
    }
  },
  actions: {
    setUser(context, user) {
      context.commit("setUser", user);
    }
  },
  modules: {
  }
})
